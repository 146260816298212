//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixinTeamOptions from "@/mixins/teamOptions";
import { imgChange } from "@/commons";
export default {
  name: "UserList",
  components: {
    Header: () => import("@/components/Header"),
    CContent: () => import("@/components/CContent"),
    CPages: () => import("@/components/CPages"),
    UserInfo: () => import("../team-management/user-info"),
    UserWage: () => import("../team-management/user-wage"),
    CreateUser: () => import("../team-management/create-user"),
    UpAccount: () => import("@/components/uploadAccount"),
  },
  mixins: [MixinTeamOptions],
  data: () => {
    return {
      ignoreStoreSchema: true,
      loading: false,
      search: null,
      teamValue: null, //选择的团队
      teamOptions: [], //团队选择器
      tableData: [],
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        totalNum: 0,
      },
      userMemberMType: 1,
      visibleMembers: [],
      selUser: null,
      tabList: [
        {
          value: 1,
          label: "批量导入",
        },
        {
          value: 2,
          label: "添加成员",
        },
      ],
      monitors: [
        { name: "实时画面", path: "/liveScreen" },
        { name: "浏览器记录", path: "/browserHistory" },
        { name: "搜索引擎记录", path: "/searchEngine" },
        { name: "聊天记录", path: "/chatHistory" },
        { name: "文件操作", path: "/fileOperation" },
        { name: "打印监控", path: "/print" },
        { name: "实时负载", path: "/realTimeLoad" },
        { name: "屏幕截图", path: "/screenshots" },
        { name: "进程数据", path: "/processData" },
      ],
    };
  },
  computed: {
    monitorsWithPower() {
      if (!this.team) {
        return [];
      } else {
        return this.monitors.filter((m) => {
          var functionName = m.name;
          if (functionName == "屏幕截图") {
            functionName = "定时截图";
          }
          if (
            this.team.Functions &&
            this.team.Functions.indexOf(functionName) != -1
          ) {
            return true;
          } else {
            return false;
          }
        });
      }
    },
  },
  methods: {
    imgChange,
    /**
     * 跳转团队监管功能
     */
    handleCommand(command, row) {
      this.selUser = row;
      this.$router.push({
        path: command.path,
        query: {
          teamId: this.teamValue,
          userId: this.selUser.UserId,
          identity: this.selUser.Identity,
        },
      });
      // var route = this.$router.resolve({
      //   path: command.path,
      //   query: { teamId: this.teamValue, userId: this.selUser.Id },
      // });
      // var ele = document.createElement("a");
      // ele.href = route.href;
      // ele.setAttribute("target", "_blank");
      // ele.click();
    },
    /**
     * 管理员添加成员
     */
    handleAddUser() {
      this.$modal.show("createUser");
    },
    /**
     * 管理员批量添加成员
     */
    handleBatchAddUser() {
      this.$modal.show("loadXls");
    },
    BatchAddUserSuccess() {
      this.handleGetData();
    },
    /**
     * 右边顶部更多操作
     * 1 批量添加成员  2 添加成员
     */
    tabChange(val) {
      if (val == 1) {
        this.handleBatchAddUser();
      } else if (val == 2) {
        this.handleAddUser();
      }
    },
    formatClientStatusColor(member) {
      var offline = this.$D.VALUE("client_status", "已离线");
      var active = this.$D.VALUE("client_status", "活动中");
      var free = this.$D.VALUE("client_status", "空闲中");
      switch (member.ClientStatus) {
        case offline:
          return "#606266";
        case active:
          return "#67C23A";
        case free:
          return "#E6A23C";
      }
    },
    formatClientStatus(member) {
      var offline = this.$D.VALUE("client_status", "已离线");
      if (offline == member.ClientStatus) {
        var str = "已离线";
        if (member.LastClientOnline) {
          str = `${member.LastClientOnline.timeFormat("yyyy-MM-dd HH:mm")}`;
        }
        return str;
      } else {
        return this.$D.ITEM("client_status", member.ClientStatus).name;
      }
    },
    /**
     * 分页
     */
    handlePaginationChange(val) {
      this.pageData = val;
      this.getList();
    },
    handleGetData() {
      this.handleSearch();
    },
    /**
     * 搜索
     */
    handleSearch() {
      this.pageData.pageIndex = 1;
      this.getList();
    },
    /**
     * 获取团队列表
     */
    getList() {
      this.loading = true;
      const data = {
        searchText: this.search,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        teamId: this.teamValue,
      };
      this.$http
        .get("/Teams/TeamMembersList.ashx", { params: data })
        .then((resp) => {
          if (resp.res == 0) {
            this.userMemberMType = resp.data.userMemberMType;
            this.visibleMembers = resp.data.visibleMembers;
            this.tableData = resp.data.data;
            this.pageData.totalNum = resp.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 查看成员详情
     */
    handleUserDetail(val) {
      this.selUser = val;
      this.$modal.show("userInfo");
    },
    /**
     * 成员设置时薪
     */
    handleHourlyWage(val) {
      this.selUser = val;
      this.$modal.show("userWageM");
    },
    /**
     * 删除成员
     */
    handleDel(row) {
      this.$confirm("此操作将删除该成员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = {
            ids: [row.Id],
            teamId: this.teamValue,
          };
          this.$http.post("/Teams/DelMembers.ashx", data).then((resp) => {
            if (resp.res == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.handleGetData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
